import { Link } from "react-router-dom";

const Navigation = () => (
  <nav className="navbar bg-body-tertiary">
    <div className="container-fluid">
      <Link
        to={"/"}
        style={{
          textDecoration: "none",
        }}
      >
        <span className="navbar-brand mb-0 h1">
          <b>Seed To Dollar</b>
        </span>
      </Link>
    </div>
  </nav>
);
export default Navigation;
