import { useState } from "react";
import BannerJPG from "../assets/banner.jpg";
import CONSTANTS from "../headers/constants";
import "./Home.css";

const currency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const Home = () => {
  const [costOfSeeds, setCostOfSeeds] = useState(1);
  const [costOfSoil, setCostOfSoil] = useState(0.5);
  const [costOfElectricity, setCostOfElectricity] = useState(0.3);
  const [costOfWater, setCostOfWater] = useState(0.3);
  const [daysPerHarvest, setDaysPerHarvest] = useState(14);
  const [ozPerYield, setOzPerYield] = useState(15);
  const [pricePerOz, setPricePerOz] = useState(1.5);
  const [numberOfTrays, setNumbersOfTrays] = useState(10);
  const [taxPercent, setTaxPercent] = useState(15);

  const taxes = (100 - taxPercent) / 100;
  const costPerTray =
    costOfSeeds + costOfSoil + costOfElectricity + costOfWater;
  const costPerMonth =
    (CONSTANTS.DAYS_IN_MONTH / daysPerHarvest) * costPerTray * numberOfTrays;
  const profitPerTray = (pricePerOz * ozPerYield - costPerTray) * taxes;
  const taxesPerTray =
    (pricePerOz * ozPerYield - costPerTray) * (taxPercent / 100);
  const profitPerYield = profitPerTray * numberOfTrays;
  const profitPerMonth =
    (CONSTANTS.DAYS_IN_MONTH / daysPerHarvest) * profitPerYield;
  const profitPerYear = profitPerMonth * 12;
  const ouncesPerMonth =
    (CONSTANTS.DAYS_IN_MONTH / daysPerHarvest) * ozPerYield;

  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundImage: `url(${BannerJPG})`,
          backgroundSize: "cover",
          padding: "50px 12px",
        }}
      >
        <h1>Micro-Green Calculator</h1>
        <p>
          Your ultimate online tool for predicting costs and profits in the
          world of micro-green cultivation. Whether you're just starting out or
          already have a setup in place, we are here to empower you with
          accurate insights.
        </p>
      </div>
      <br />
      <br />
      <div>
        <div className="input-group mb-3">
          <span className="input-group-text">Cost Per Tray</span>
          <input
            type="text"
            className="form-control"
            value={currency.format(costPerTray)}
            disabled
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Cost Per Month</span>
          <input
            type="text"
            className="form-control"
            value={currency.format(costPerMonth)}
            disabled
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Ounces Per Month</span>
          <input
            type="text"
            className="form-control"
            value={Math.floor(ouncesPerMonth)}
            disabled
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Taxes Per Tray</span>
          <input
            type="text"
            className="form-control"
            value={currency.format(taxesPerTray)}
            disabled
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Profit Per Tray</span>
          <input
            type="text"
            className="form-control"
            value={currency.format(profitPerTray)}
            disabled
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Profit Per Yield</span>
          <input
            type="text"
            className="form-control"
            value={currency.format(profitPerYield)}
            disabled
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Profit Per Month</span>
          <input
            type="text"
            className="form-control"
            value={currency.format(profitPerMonth)}
            disabled
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Profit Per Year</span>
          <input
            type="text"
            className="form-control"
            value={currency.format(profitPerYear)}
            disabled
          />
        </div>
      </div>
      <div
        className="container-fluid"
        style={{
          padding: "12px",
        }}
      >
        <br />
        <br />
        <label className="form-label">
          Seeds per tray{" "}
          <span className="badge text-bg-success">
            {currency.format(costOfSeeds)}
          </span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setCostOfSeeds(parseFloat(e.target.value))}
          min={CONSTANTS.MIN_COST_FOR_SEEDS_PER_TRAY}
          max={CONSTANTS.MAX_COST_FOR_SEEDS_PER_TRAY}
          value={costOfSeeds}
          step={0.1}
        ></input>
        <br />
        <br />
        <label className="form-label">
          Soil per tray{" "}
          <span className="badge text-bg-success">
            {currency.format(costOfSoil)}
          </span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setCostOfSoil(parseFloat(e.target.value))}
          min={CONSTANTS.MIN_COST_FOR_SOIL_PER_TRAY}
          max={CONSTANTS.MAX_COST_FOR_SOIL_PER_TRAY}
          value={costOfSoil}
          step={0.1}
        ></input>
        <br />
        <br />
        <label className="form-label">
          Electricity per tray{" "}
          <span className="badge text-bg-success">
            {currency.format(costOfElectricity)}
          </span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setCostOfElectricity(parseFloat(e.target.value))}
          min={CONSTANTS.MIN_COST_FOR_ELECTRIC_PER_TRAY}
          max={CONSTANTS.MAX_COST_FOR_ELECTRIC_PER_TRAY}
          value={costOfElectricity}
          step={0.1}
        ></input>
        <br />
        <br />
        <label className="form-label">
          Water per tray{" "}
          <span className="badge text-bg-success">
            {currency.format(costOfWater)}
          </span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setCostOfWater(parseFloat(e.target.value))}
          min={CONSTANTS.MIN_COST_FOR_WATER_PER_TRAY}
          max={CONSTANTS.MAX_COST_FOR_WATER_PER_TRAY}
          value={costOfWater}
          step={0.1}
        ></input>
        <br />
        <br />
        <label className="form-label">
          Days per harvest{" "}
          <span className="badge text-bg-success">{daysPerHarvest} days</span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setDaysPerHarvest(parseFloat(e.target.value))}
          min={CONSTANTS.MIN_DAYS_TO_HARVEST}
          max={CONSTANTS.MAX_DAYS_TO_HARVEST}
          value={daysPerHarvest}
          step={1}
        ></input>
        <br />
        <br />
        <label className="form-label">
          Ounces per yield per tray{" "}
          <span className="badge text-bg-success">{ozPerYield} ounces</span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setOzPerYield(parseFloat(e.target.value))}
          min={CONSTANTS.MIN_OZ_YIELD_PER_TRAY}
          max={CONSTANTS.MAX_OZ_YIELD_PER_TRAY}
          value={ozPerYield}
          step={1}
        ></input>
        <br />
        <br />
        <label className="form-label">
          Price per ounce{" "}
          <span className="badge text-bg-success">
            {currency.format(pricePerOz)}
          </span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setPricePerOz(parseFloat(e.target.value))}
          min={CONSTANTS.MIN_SELLING_PRICE_PER_OZ}
          max={CONSTANTS.MAX_SELLING_PRICE_PER_OZ}
          value={pricePerOz}
          step={0.25}
        ></input>
        <br />
        <br />
        <label className="form-label">
          Number of trays{" "}
          <span className="badge text-bg-success">{numberOfTrays} trays</span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setNumbersOfTrays(parseFloat(e.target.value))}
          min={4}
          max={50}
          value={numberOfTrays}
          step={1}
        ></input>
        <br />
        <br />
        <label className="form-label">
          Tax percent{" "}
          <span className="badge text-bg-success">{taxPercent}%</span>
        </label>
        <input
          type="range"
          className="form-range"
          onChange={(e) => setTaxPercent(parseFloat(e.target.value))}
          min={10}
          max={50}
          value={taxPercent}
          step={5}
        ></input>
      </div>
    </>
  );
};

export default Home;
