const Footer = () => {
  return (
    <div
      className="container"
      style={{
        textAlign: "center",
        fontSize: "12px",
        padding: "12px 0",
      }}
    >
      <p>
        <b>Seed To Dollar</b> | Copyright 2023
        <br />
        Developed by @JadeAllenCook
        <br />
      </p>
    </div>
  );
};

export default Footer;
