const CONSTANTS = {
  MIN_COST_FOR_TRAY: 1,
  MAX_COST_FOR_TRAY: 10,

  MIN_COST_FOR_SEEDS_PER_TRAY: 0.5,
  MAX_COST_FOR_SEEDS_PER_TRAY: 5,

  MIN_COST_FOR_SOIL_PER_TRAY: 0.1,
  MAX_COST_FOR_SOIL_PER_TRAY: 5,

  MIN_COST_FOR_ELECTRIC_PER_TRAY: 0,
  MAX_COST_FOR_ELECTRIC_PER_TRAY: 2,

  MIN_COST_FOR_WATER_PER_TRAY: 0,
  MAX_COST_FOR_WATER_PER_TRAY: 1,

  DAYS_IN_MONTH: 30,
  MIN_DAYS_TO_HARVEST: 7,
  MAX_DAYS_TO_HARVEST: 30,

  MIN_OZ_YIELD_PER_TRAY: 8,
  MAX_OZ_YIELD_PER_TRAY: 25,
  MIN_SELLING_PRICE_PER_OZ: 1,
  MAX_SELLING_PRICE_PER_OZ: 3,
};

export default CONSTANTS;
